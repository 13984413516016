<template>
<div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    {{ param.headerTitle }}
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div>
      <v-form ref="form"
            v-model="valid"
            lazy-validation
    >
      <div class="table-form">
        <table>
          <caption class="hide">
            정보 항목
          </caption>
          <colgroup>
            <col width="57px" />
            <col width="" />
            <col width="127px" />
            <col width="" />
          </colgroup>
            <tbody>
             
            
                <tr>
                    <th scope="row">
                        고객이름
                    </th>
                    <td colspan="3">
                    <v-text-field
                        class="form-inp full"
                        name="CUST_NAME"
                        outlined
                        v-model="CUST_NAME"
                        maxlength="50"
                        required
                    >
                    </v-text-field>
                    </td>
                </tr>
                
                <tr>
                    <th scope="row">
                        전화번호
                    </th>
                    <td colspan="3">
                    <v-text-field
                        class="form-inp full"
                        name="CUST_PHONE"
                        outlined
                        v-model="CUST_PHONE"
                        maxlength="11"
                        required
                        :disabled="disabledCustPhone"
                    >
                    </v-text-field>
                    </td>
                </tr>
  
            </tbody>
        </table>
      </div>
      </v-form>
      <div class="text-right mt-3">
        <v-btn  outlined class="btn-default" @click="submit"  >저장</v-btn>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mixin} from "@/mixin/mixin";

export default {
  name: "MENU_CUP0100", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
  },
  mixins:[mixin],
  data(){
    return {

      valid: true, 
      footerHideTitle: "닫기",
      CUST_NAME:"",
      CUST_PHONE:"",
      disabledCustPhone:true,

    }
  },
  methods: {

    init() {



      let type = this.param.type;

      if (type == "NEW") {//신규

        this.CUST_NAME= "";
        this.CUST_PHONE= ""; 
        this.disabledCustPhone= false;

      } else {//상세

        this.CUST_NAME= this.param.custName;
        this.CUST_PHONE= this.param.custPhone;
        this.disabledCustPhone= true;
      }
    },
  
   validate () {
     
  
      if( this.CUST_PHONE == '' ){
        const msg = '전화번호는 필수입니다.';
        this.common_alert(msg, 'error');

        return false;
      }
      if( this.CUST_NAME == '' ){
        const msg = '고객이름 필수입니다.';
        this.common_alert(msg, 'error');

        return false;
      }

      return true;
    },
 

    async submit() {
   
      if(!this.validate()){
        return;
      }
      let requestData = {
        headers: {
          SERVICE: this.initHeaders.SERVICE,
          METHOD: this.initHeaders.METHOD,
          TYPE: this.initHeaders.TYPE,
        },
        sendData: {},
      };


      requestData.headers["URL"] = "/api/hlw/campaign/cust-custInfo/manage/regist";
      requestData.headers["SERVICE"] = "hlw.campaign.cust-custInfo.manage";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["ASYNC"] = false;
      requestData.sendData["CUST_TYPE"]   =  this.param.type;
      requestData.sendData["BUSS_CODE"]   = "CUM0300";
      requestData.sendData["CUST_PHONE"]  = this.CUST_PHONE;
      requestData.sendData["CUST_NAME"]   = this.CUST_NAME;
      requestData.sendData["REGR_ID"]     = this.$store.getters['userStore/GE_USER_ROLE'].userId;
 
      const response = await this.common_postCall(requestData);
      this.submitCallBack(response);



    },

    submitCallBack(response) {

      if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
        if(response.HEADER.ERROR_MSG == "Y"){
          this.common_alert("전화번호가 존재합니다.", "error");
        }else{
          this.common_alert("시스템에 오류가 발생하였습니다.", "error");
        }
        return false;
      }
      this.common_alert("정상적으로 처리되었습니다.", "done");
      this.$emit('hide');
    },
  

  },
  async mounted(){
    this.init();
  },
  computed: {
    initHeaders(){
      return {
        SERVICE: '',
        METHOD: "",
        TYPE: 'BIZ_SERVICE',
      };
    },
    paramData(){

    }
  },
};
</script>

<style></style>
 